import { useState, useRef, useEffect } from 'react';

function checkLang () {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const lng = urlParams.get('lng');
    if (lng === 'en') {
        return 'EN'
    } else {
        return 'TH'
    }
}

function Header() {
    const [isClick, toggleClick] = useState(false);

    const setIsActive = () => {
        toggleClick(!isClick);
    };

    /**
     * Hook that alerts clicks outside of the passed ref
     */
    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    toggleClick(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <section className="header">
            <div className="header-container">
                <img className="swap-logo-header" src="/images/header/logo.png" alt="swap-logo"></img>

                <div ref={wrapperRef}>
                    <span id="button-lang" onClick={ setIsActive } className={`container-button-lang ${isClick ? "-active" : ""}`} >
                        <div className="container-left-button-lang">
                            <div className="container-icon-lang">
                                <img className="icon-lang" src={ checkLang() === "EN" ? "/images/header/en.png" : "/images/header/th.png" } alt="swap-logo"></img>
                            </div>
                            <span className="text-lang">{ checkLang() }</span>
                        </div>
                        <span>
                            <img className="icon-lang" src="/images/header/arrow-down.png" alt="swap-logo"></img>
                        </span>
                    </span>

                    <a
                        href={checkLang() === "EN" ? "?lng=th" : "?lng=en" }
                        id="button-lang"
                        className={`container-button-lang button-toggle-lang ${isClick ? "-show" : "-hide"}`}
                    >
                        <span className="container-left-button-lang">
                            <div className="container-icon-lang">
                                <img className="icon-lang" src={ checkLang() === "EN" ?  "/images/header/th.png" : "/images/header/en.png" } alt="swap-logo"></img>
                            </div>
                            <span className="text-lang">{ checkLang() === "EN" ? "TH" : "EN" }</span>
                        </span>
                        <span>
                        </span>
                    </a>
                </div>
            </div>
        </section>
    );
}

export default Header;