// import logo from './logo.svg';
import './App.css';
import './style/partnership.css';
import './style/readyToSwap.css';
import './style/contactUs.css';
import './style/footer.css';
import './style/header.css';
import './style/banner.css';

import Banner from './components/Banner';
import WhatIsSwap from './components/WhatIsSwap';
import Header from './components/Header';
import HowItWorks from './components/HowItWorks';
import Partner from './components/Partner';
import ReadyToSWAP from './components/ReadyToSWAP';
import ContactUs from './components/ContactUs'
import Footer from './components/Footer.js'

function App() {
  return (
    <div className="App">
        <Header />
        <Banner />
        <WhatIsSwap />
        <HowItWorks />
        <Partner />
        <ReadyToSWAP />
        <ContactUs />
        <Footer />
    </div>
  );
}

export default App;
