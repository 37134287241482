import React from "react";
import { useTranslation } from 'react-i18next';

function ReadyToSWAP() {
    const { t } = useTranslation('landing_page');

    return (
        <section className="hiw-bg">
            <div className="container">
                <div className="container-ready-to-swap">
                    <div className="container-left-ready-to-swap">
                        <div className="container-content-ready-to-swap">
                            <p className="text-ready-to-swap">
                                <span className="text-ready-to">
                                    { t('ready_to_swap.ready_to') }
                                    <br className="show-only-mobile" />
                                </span>
                                <span className="container-swap-logo">
                                    <img className="swap-logo" src="/images/ready_to_swap/logo.png" alt="Recycle"/>
                                    <span className="question-mark-text">?</span>
                                </span>
                            </p>
                            <div className="container-mobile-button">
                                <a
                                    className="link-download"
                                    href="https://play.google.com/store/apps/details?id=earth.secondlife.swap_recycling"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <img className="image-download-app" src="/images/download_button/google-play.png" alt="Google Play" />
                                </a>
                                <a
                                    className="link-download"
                                    href="https://apps.apple.com/app/id1625627142"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <img className="image-download-app" src="/images/download_button/app-store.png" alt="App Store" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="container-right-ready-to-swap">
                        <img className="image-bin" src="/images/ready_to_swap/ready-to-swap-right.png" alt="Bin" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ReadyToSWAP;