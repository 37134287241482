import React from "react";
import { useTranslation } from 'react-i18next';

function Partner() {
    const { t } = useTranslation('landing_page');

    return (
        <section className="section-partner-ship">
            <div className="wave-container">
                    <img src="/images/partnership/background-top.png" alt="Background top" className="background-top" />
            </div>
            <div className="container container-partner-section">
                <div className="partner-content">
                    <div className="group-content">
                        <div className="partner-header">
                            <img className="leafIcon" src="/images/banner/leaf.png" alt="leaf-icon"></img>
                            <p className="title">{ t('partnership.title') }</p>
                        </div>

                        <div className="partner-desc">
                            { t('partnership.desc_1') }
                            <br className="show-tablet-and-desktop-only" />
                            { t('partnership.desc_2') }
                            <br className="show-tablet-and-desktop-only" />
                            { t('partnership.desc_3') }
                        </div>
                    </div>

                    <div className="partner-logo">
                        <a
                            className="container-logo-partner"
                            href="https://www.facebook.com/greenroad.enterprise/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img className="logo-partner" src="/images/partnership/green_road.png" alt="Green road logo"></img>
                        </a>
                        <a
                            className="container-logo-partner"
                            href="https://secondlife.earth/contact/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img className="logo-partner" src="/images/partnership/2nd_life.png" alt="2nd Life logo"></img>
                        </a>
                        <a
                            className="container-logo-partner"
                            href="https://www.mars.com/made-by-mars/petcare"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img className="logo-partner" src="/images/partnership/mars_petcare.png" alt="MARS Petcare logo"></img>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Partner;