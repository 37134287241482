import React from "react";
import { useTranslation } from 'react-i18next';

function WhatIsSwap() {
    const { t } = useTranslation('landing_page');

    return (
        <div className="detail-bg">
            <div className="container">
                <div className="detail-content">
                    <div className="group-content-what-is-swap">
                        <div className="title-align-section-2">
                            <img className="leafIcon" src="/images/banner/leaf.png" alt="Leaf icon"></img>
                            <p className="title">{ t('what_is_swap.header') }</p>
                        </div>
                        <p className="desc">
                            { t('what_is_swap.content') }
                        </p>
                    </div>

                    <div className="recycling-step">
                        <div className="step-box">
                            <img className="recycling-img" src="/images/what_is_swap/recycling-1.png" alt="Collect plastic waste"></img>
                            <p className="recycling-step-text">
                                { t('what_is_swap.collect_plastic') }
                            </p>
                        </div>

                        <img className="polygon-arrow" src="/images/what_is_swap/polygon-arrow.png" alt="polygon arrow"></img>

                        <div className="step-box">
                            <img className="recycling-img" src="/images/what_is_swap/recycling-2.png" alt="Send the waste to us conveniently"></img>
                            <p className="recycling-step-text">
                                { t('what_is_swap.send_the_waste') }
                            </p>
                        </div>

                        <img className="polygon-arrow" src="/images/what_is_swap/polygon-arrow.png" alt="polygon arrow"></img>

                        <div className="step-box">
                            <img className="recycling-img" src="/images/what_is_swap/recycling-3.png" alt="Collect points and redeem rewards"></img>
                            <p className="recycling-step-text">
                                { t('what_is_swap.collect_point') }
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhatIsSwap;