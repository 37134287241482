import React from "react";
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation('landing_page');

    return (
        <div className="container-footer">
            <div className="container-footer-logo">
                <img
                    className="footer-logo"
                    src="/images/footer/logo.png"
                    alt="swap-logo"
                />
            </div>
            <p className="right-reserved-text">
                { t('footer.right_reserved') }
            </p>
        </div>
    );
}

export default Footer;