import React from "react";
import { useTranslation } from 'react-i18next';

function HowItWorks() {
    const { t } = useTranslation('landing_page');

    return (
        <section className="hiw-bg">
            <div className="wave-container">
                <img src="/images/how_it_works/hiw-wave.png" className="top-wave" alt="wave" />
            </div>
            <div className="container">
                <div className="hiw-content">
                    <div className="title-align-section show-tablet-and-mobile-only">
                       <img className="leafIcon" src="/images/banner/leaf.png" alt="Leaf icon" />
                        <p className="title">{ t('how_it_works.header') }</p>
                    </div>

                    <img src="/images/how_it_works/mobile-2.png" className="hiw-img" alt="Swap recycling interface application"/>

                    <div className="hiw-info" id="hiw-order-list">
                        <div className="title-align-section show-desktop-only">
                            <img className="leafIcon" src="/images/banner/leaf.png" alt="Leaf icon" />
                            <p className="title">{ t('how_it_works.header') }</p>
                        </div>
                        <div className="hiw-list">
                            <ul>
                                <li>
                                    <div className="icon-bullet-align">
                                        <img className="hiw-icon" src="/images/how_it_works/hiw-icon-1.png" alt="Delivery icon" />
                                        <div className="list-info-vstack">
                                            <p className="title-2">{ t('how_it_works.sub_header_1') }</p>
                                            <p className="desc-2">{ t('how_it_works.content_1') }</p>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div className="icon-bullet-align">
                                        <img className="hiw-icon" src="/images/how_it_works/hiw-icon-2.png" alt="Recycling icon" />
                                        <div className="list-info-vstack">
                                            <p className="title-2">{ t('how_it_works.sub_header_2') }</p>
                                            <p className="desc-2">{ t('how_it_works.content_2') }</p>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div className="icon-bullet-align">
                                        <img className="hiw-icon" src="/images/how_it_works/hiw-icon-3.png" alt="Gift icon" />
                                        <div className="list-info-vstack">
                                            <p className="title-2">{ t('how_it_works.sub_header_3') }</p>
                                            <p className="desc-2">{ t('how_it_works.content_3') }</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HowItWorks;