import React from "react";
import { useTranslation } from 'react-i18next';

function Banner() {
    const { t } = useTranslation('landing_page');

    return (
        <div className="banner-bg">
            <div className="container">
                <div className="banner-content">
                    <div className="banner-text">
                        <img className="leafIcon" src="/images/banner/leaf.png" alt="Leaf icon"></img>
                        <p className="container-banner-text">
                            { t('banner.content_1') }<br />
                            { t('banner.content_2') }<br />
                            { t('banner.content_3') }<br />
                        </p>

                        <div className="download-btn">
                            <a href="https://play.google.com/store/apps/details?id=earth.secondlife.swap_recycling">
                                <img src="/images/download_button/google-play.png" className="download-btn-img" alt="Playstore download button"></img>
                            </a>
                            <a href="https://apps.apple.com/app/id1625627142">
                                <img src="/images/download_button/app-store.png" className="download-btn-img" alt="App store download button"></img>
                            </a>
                        </div>
                    </div>

                    <div className="container-image-right">
                        <img src="/images/banner/mobile-screenshot.png" className="banner-img" alt="Swap recycling interface application" ></img>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Banner;