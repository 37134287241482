import { useTranslation } from 'react-i18next';

function ContactUsSection() {
    const { t } = useTranslation('landing_page');

    return (
        <div className="container-contact-us">
            <div className="container-header-contact-us">
                <img
                    src="/images/banner/leaf.png"
                    alt="leaf-icon"
                    className="leafIcon leaf-icon-contact-us"
                />
                <h2 className="header-contact-us">
                    { t('contact_us.header_contact_us') }
                </h2>
            </div>
            <div className="container-content-contact-us">
                <a
                    href="https://www.facebook.com/greenroad.enterprise/"
                    rel="noreferrer"
                    target="_blank"
                    className="button-contact-us"
                >
                    <img className="logo-contact-us" src="/images/contact_us/facebook.png" alt="facebook-icon"></img>
                    <span>GREEN ROAD</span>
                </a>

                <a
                    href="https://lin.ee/5akpF4S"
                    rel="noreferrer"
                    target="_blank"
                    className="button-contact-us"
                >
                    <img className="logo-contact-us" src="/images/contact_us/line.png" alt="line-icon"></img>
                    <span>GREEN ROAD</span>
                </a>
            </div>
        </div>
    );
}

export default ContactUsSection;